import React, {Component, createRef} from 'react'
import OwnButton from './ownButton.jsx'
import './style.scoped.css'
import './icons/codicon.css'

class PopUp extends Component{
    constructor(){
        super()
        this.state = {
            style:{
                height: 0
            }
        }
        this.Element = createRef()
        this.click = this.click.bind(this)
        this.animationEnd = this.animationEnd.bind(this)
        this.removeElement = this.removeElement.bind(this)
    }
    componentDidMount()
    {
        this.addElement()
    }
    click(e){
        const props = this.props,
              {onClick} = props
        if(typeof onClick === "function")
        {
            onClick()
        }
        if(onClick !== null || e === 'forceClose')
        {
            this.removeElement()
        }
    }
    animationEnd(e)
    {
        const props = this.props,
              {timer, onRemove, animationStart, animationEnd, animationEndDuration, Hide} = props,
              Element = this.Element.current

        if(e.animationName === animationStart)
        {
            if(timer === 0)
            {
                return true
            }
            setTimeout(() => {
                Element.children[0].style.animation = animationEnd+" "+animationEndDuration+" forwards"
            }, timer);
        }
        if(e.animationName === animationEnd && !Hide)
        {
                Element.className += " hide"
                setTimeout(() => {
                    return onRemove(props,Element)
                }, sToTime(animationEndDuration));
        }
    }

    componentWillReceiveProps(props)
    {
        const {Remove, replace, Hide} = props
        if((replace && Hide) || Remove)
        {
            this.removeElement()
        }
        else if(replace)
        {
            this.addElement()
        }
    }
    removeElement()
    {
        const {animationEnd, animationEndDuration} = this.props,
              Element = this.Element.current
        Element.children[0].style.animation = animationEnd+" "+animationEndDuration+" forwards"
    }
    addElement()
    {        
        const  {animationStart, animationStartDuration} = this.props,
                Element = this.Element.current,
                {scrollHeight} = Element
        Element.children[0].style.animation = animationStart+" "+animationStartDuration+" forwards"
        this.setState({
            style:{
                height: scrollHeight
            }
        })
    }
    render(){
        const {error, warn, info, ownClass, ownButton} = this.props,
              {Element,state} = this
        let {type, message, icon, Button} = ''
        if(typeof error !== 'undefined')
        {
            message = error
            type = "error"
        }
        if(typeof warn !== 'undefined')
        {
            message = warn
            type = "warning"
        }
        if(typeof info !== 'undefined')
        {
            message = info
            type = "info"
        }
        
        icon = type

        if(this.props.icon !== null)
        {
            message = this.props.message || message
            type = this.props.type || type
            icon = this.props.icon
        }
        if(this.props.message !== null)
        {
            message = this.props.message
            type = this.props.type || type
            icon = this.props.icon || icon
        }
        if(this.props.type !== null)
        {
            type = this.props.type
        }
        if(error === null || warn === null || info === null)
        {
            return null
        }
        if(ownButton !== null)
        {
            Button = <OwnButton {...ownButton} />
        }
        return(
            <div className={"popup-container"+(ownClass?' '+ownClass:'')} style={state.style} ref={Element} onClick={() => this.click()}>
                <div className={"popup popup-"+type} onAnimationEnd={(e) => this.animationEnd(e)}>
                    <i className="popup-close codicon codicon-close" onClick={()=> this.click('forceClose')}></i>
                    <i className={"codicon codicon-"+icon}></i>
                    <span>{message}</span>
                    {Button}
                </div>
            </div>
        )
    }
}

export default PopUp

function sToTime(s)
{
    let $x = Number(s.replace(/[^0-9.-]+/g,""))
    $x = ($x % 100) * 1000
    return $x

}