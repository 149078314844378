window.$ajax = function($data,callback=()=>{},error=()=>{}){
    const data = getProperties($data);
    fetch(window.MainApiUrl+'/'+data.file+'.php',{
        method:data.method,
        headers: data.headers,
        body:data.body
    })
    .then((e)=>e.json())
    .then((data)=>{
        let $data = data !== null ? data : [];
        if(window.checkJSON($data))
        {
            for(const [key, value] of Object.entries($data))
            {
                if(Array.isArray(value))
                {
                    value.map((e,i)=>{
                        for(const [k, v] of Object.entries(e))
                        {
                            if(window.checkJSON(v) && typeof v !== "object")
                            {
                                $data[key][i][k] = JSON.parse(v);
                            }
                        }
                        return null;
                    })  
                }
            }
        }
        callback($data);
    })
    .catch((err)=>{
        error(err);
        console.log(err);
    })
}
function getProperties(e)
{
    const props = {},
        {
            method,
            body,
            mode,
            file,
            headers
        } = e

        props.method = method || "GET"
        props.body = body || null
        props.mode = mode || "cors"
        props.headers = headers || {'Accept': 'application/json','Content-Type': 'application/json'}
        props.file = file

    return props;
}


FindObjects(process.env,'react_app_');

function FindObjects(obj,s){
    let returnedObj = {};
    Object.keys(obj).forEach((key)=>{
        let keyName = key.replace(s,'')
        if(key.indexOf(s) !== -1)
        {
            window[keyName] = obj[key]
            returnedObj[keyName] = obj[key]
        }
    })
    return returnedObj
}


function Ajax(data = {what:"",select:"",where:"",multiple:false},callback=()=>{})
{
    let {what,select,where,multiple} = data
    if(typeof data === "string")
    {
        what = data;
    }
    if(Array.isArray(select))
    {
       multiple = true;
    }
    window.$ajax({
        method:"POST",
        file:"get",
        body:JSON.stringify({what,select,where,multiple})
    },(e)=>{
        callback(e)
    })
}


function sendMail(data = {to:"",text:"",subject:"",infoEmail:"",button:"",buttonLink:"",headText:"",color:"",addPeople:""},callback=()=>{}) {
    let key = "0540b8d81bdeca8290e5c8d058bb99eb11607816f4f8790d35dc2f6a329375512c7329a1c47b073e696de2f6f48cff488b7bd9cc1f7b499ae987690669e0361a"
    let {to,text,subject,infoEmail,headText,button,buttonLink,color,addPeople} = data
    
    window.$ajax({
        method:"POST",
        file:"/email/send",
        body:JSON.stringify({to,text,subject,infoEmail,headText,button,buttonLink,color,key,addPeople})
    },(e)=>{
        callback(e)
    })

}

function verifaceCreate({id,personId},callback = ()=>{}) {

    fetch(window.MainApiUrl+'/vf.php',{
        method:"POST",
        headers: {'Accept': 'application/json','Content-Type': 'application/json'},
        body:JSON.stringify({ID:id,personId,what:"create"})
    })
    .then((e)=>e.json())
    .then(callback)
    
}

export {Ajax,sendMail,verifaceCreate}