// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body{margin:0;font-family:"Montserrat",-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;overflow:hidden}code{font-family:source-code-pro,Menlo,Monaco,Consolas,"Courier New",monospace}.app{width:100vw;height:100vh;overflow:auto}.not-found{background-color:#fff;width:100%;height:90%;display:flex;flex-direction:column;flex-wrap:nowrap;justify-content:center;align-items:center}.not-found a{color:#000;text-decoration:none}`, "",{"version":3,"sources":["webpack://./App/style/index.scss"],"names":[],"mappings":"AAAA,KAAK,QAAQ,CAAC,gKAAgK,CAAC,kCAAkC,CAAC,iCAAiC,CAAC,eAAe,CAAC,KAAK,yEAAyE,CAAC,KAAK,WAAW,CAAC,YAAY,CAAC,aAAa,CAAC,WAAW,qBAAqB,CAAC,UAAU,CAAC,UAAU,CAAC,YAAY,CAAC,qBAAqB,CAAC,gBAAgB,CAAC,sBAAsB,CAAC,kBAAkB,CAAC,aAAa,UAAU,CAAC,oBAAoB","sourcesContent":["body{margin:0;font-family:\"Montserrat\",-apple-system,BlinkMacSystemFont,\"Segoe UI\",\"Roboto\",\"Oxygen\",\"Ubuntu\",\"Cantarell\",\"Fira Sans\",\"Droid Sans\",\"Helvetica Neue\",sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;overflow:hidden}code{font-family:source-code-pro,Menlo,Monaco,Consolas,\"Courier New\",monospace}.app{width:100vw;height:100vh;overflow:auto}.not-found{background-color:#fff;width:100%;height:90%;display:flex;flex-direction:column;flex-wrap:nowrap;justify-content:center;align-items:center}.not-found a{color:#000;text-decoration:none}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"app": `app`,
	"not-found": `not-found`
};
export default ___CSS_LOADER_EXPORT___;
