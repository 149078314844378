var Q = function ( query , NodeElements = false) {
    return new Q.fn.init( query , NodeElements);
}

Q.fn = Q.prototype = {
    constructor: Q,
    each: function(callback){
        return Q.each(this, callback)
    },
    addAttr: function(opt,val){
        this.each((e)=>{
            e.setAttribute(opt,val);
        })
        return this;
    },
    removeAttr: function(opt)
    {
        this.each((e)=>{
            e.removeAttribute(opt);
        })
        return this;
    },
    getStyle: function()
    {   
        let style = window.getComputedStyle(this[0]),
            matrix = new DOMMatrix(style.transform);

        return matrix.m41;

    },
    css: function(options,callback=()=>{})
    {
        if(typeof options === "string")
        {
            this.each((e)=>{
                e.style = options;
            })
        }
        else if(typeof options === "object")
        {
            for(let i in options)
            {
                this.each((e)=>{
                    e.style[i] = options[i];
                })
            }
        }
        callback()
        return this;
    },
    
    setCss: function(options,callback=()=>{})
    {
        if(typeof options === "object")
        {
            for(let [key,val] of Object.entries(options))
            {
                this.each((e)=>{
                    e.style.setProperty(key,val);
                })
            }
        }
        callback()
        return this;
    },
    class: function(method,value){
        
        let $arr = [];
        if(typeof method === 'undefined')
        {
            this.each((e,i)=>{
                $arr.push(  {el:e ,classes: e.classList});
            })
            return $arr;
        }
        let has=null;
        this.each((e)=>{
            if(method === "add")
            {
                e.classList.add(value);
            }
            if(method === "remove")
            {
                e.classList.remove(value);
            }
            if(method === "toggle")
            {
                e.classList.toggle(value);
            }
            if(method === "has")
            {
                has = e.classList.contains(value);
                $arr.push(  {el:e ,has: has});
            }
        })
        return has!== null? $arr :this;
    }
};


Q.fn.init = function( query , NodeElements ) {
    let elements = typeof query === "object"?query:document.querySelectorAll(query),
        $obj = this;
    if(typeof query === "object")
    {
        $obj[0] = elements;
    }
    else
    {
        elements.forEach((val,key)=>{
            $obj[key] = val
        })
    }
    if(NodeElements)
    {
        return elements;
    }
    return this;

}

Q.fn.init.prototype = Q.fn;

Q.each = function each( obj, callback ) {
    var length, i = 0;
    
    if ( isArrayLike( obj ) ) {
        length = obj.length;
        for ( ; i < length; i++ ) {
            
            if (typeof obj[i] === "function") {
                continue;
            }
            if ( callback.call( obj[ i ], i, obj[ i ] ) === false ) {
                break;
            }
        }
    } else {
        for ( i in obj ) {
            if (typeof obj[i] === "function") {
                continue;
            }
            if ( callback.call( obj[ i ] , obj[i] , i) === false ) {
                break;
            }
        }
    }

    return obj;
}



if ( typeof noGlobal === "undefined" ) {
	window.Q = window.$Q = Q;
}

function isFunction( obj ) {
    return typeof obj === "function" && typeof obj.nodeType !== "number";
};
function isWindow( obj ) {
    return obj != null && obj === obj.window;
};

function isArrayLike( obj ) {
	var length = !!obj && "length" in obj && obj.length,
		type = toType( obj );

	if ( isFunction( obj ) || isWindow( obj ) ) {
		return false;
    }
	return type === "array" || length === 0 ||
		(typeof length === "number" && length > 0 && ( length - 1 ) in obj);
}
function toType( obj ) {
	if ( obj == null ) {
		return obj + "";
	}
	return typeof obj === "object" || typeof obj === "function" ? "object" : typeof obj;
}


export default Q