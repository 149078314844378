import {PrototipeProperties} from './utils/variables.jsx'

function PopUps() {
    this.counter = 0;
    this.add = () => {};
    this.removePopUp = () => {};

    this.addPopUp = (PopUp) => {
            this.counter += 1;
            PopUp.id = PopUp.id || this.counter;
        const PopUpVal = PrototipeProperties(PopUp)
      return this.add(PopUpVal);
    };
  
    this.declare = ({ addPopUp, removePopUp }) => {
      this.add = addPopUp;
      this.removePopUp = removePopUp;
    };
  
    return this;
  }
  
  const popups = new PopUps();
  export default popups;